/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 24/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React from "react";
import { render } from "react-dom";
import Widget from "../components/widget/Widget";
import injectStyles from "../util/injectStyles";
import loggerInit from "../util/loggerInit";

loggerInit("[Campable Widget] %s");

const domElements = document.querySelectorAll("[id^=campable-widget]");
for (let i = 0; domElements[i]; i++) {
    const dom = domElements[i] as HTMLElement;
    if (dom) {
        injectStyles("widget.css", "campable-widget-css");
        render(<Widget dom={dom} />, dom);
    }
}
