/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 26/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export default function getDomAttribute(dom: HTMLElement, key: string) {
    const attributes = dom.attributes;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const attribute = attributes[key];

    if (!attribute) {
        return null;
    }

    return attribute.value;
}
