/* eslint-disable @typescript-eslint/ban-ts-ignore */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 26/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import util from "util";

const funcs = ["log", "info", "warn", "error", "debug"];

export default function loggerInit(stringFormat: string): void {
    funcs.forEach((key: string) => {
        // @ts-ignore
        const oldFunc = window.console[key] || window.console.log;
        // @ts-ignore
        window.console[key] = (...params: any[]) => {
            params[0] = util.format(stringFormat, params[0]);
            oldFunc(...params);
        };
    });
}
