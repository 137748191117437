/* eslint-disable @typescript-eslint/ban-ts-ignore */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 24/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Fragment, Suspense } from "react";
import getDomAttribute from "../../util/getDomAttribute";
import "./Widget.scss";
// import Widget300x250 from "./widgets/widget300x250/Widget300x250";
// import Widget300x600 from "./widgets/widget300x600/Widget300x600";
// import Widget970x250 from "./widgets/Widget970x250/Widget970x250";

// @ts-ignore
const Widget300x250 = React.lazy(() => import("./widgets/widget300x250/Widget300x250"));
// @ts-ignore
const Widget300x600 = React.lazy(() => import("./widgets/widget300x600/Widget300x600"));
// @ts-ignore
const Widget728x90 = React.lazy(() => import("./widgets/widget728x90/Widget728x90"));
// @ts-ignore
const Widget970x250 = React.lazy(() => import("./widgets/widget970x250/Widget970x250"));
// @ts-ignore
const Widget160x900 = React.lazy(() => import("./widgets/widget160x900/Widget160x900"));

type Props = {
    dom: HTMLElement;
};

enum WidgetSize {
    s300x250 = "300x250",
    s300x600 = "300x600",
    s728x90 = "728x90",
    s970x250 = "970x250",
    s160x900 = "160x900",
}

export default function Widget(props: Props) {
    const dom = props.dom;
    const widgetSize = getDomAttribute(dom, "data-widget-size");
    const affiliateLink = getDomAttribute(dom, "data-affiliate-link");

    if (!widgetSize) {
        console.error("No widget size supplied.");
    }

    if (!affiliateLink) {
        console.error("No affiliate link supplied.");
    }

    dom.onclick = () => {
        window.open(affiliateLink);
    };

    let Widget = null;
    switch (widgetSize) {
        case WidgetSize.s300x250: {
            Widget = Widget300x250;
            break;
        }
        case WidgetSize.s300x600: {
            Widget = Widget300x600;
            break;
        }
        case WidgetSize.s728x90: {
            Widget = Widget728x90;
            break;
        }
        case WidgetSize.s970x250: {
            Widget = Widget970x250;
            break;
        }
        case WidgetSize.s160x900: {
            Widget = Widget160x900;
            break;
        }
        default: {
            Widget = null;
        }
    }

    if (!Widget) {
        return null;
    }

    return (
        <Suspense fallback={<Fragment />}>
            <Widget dom={dom} />
        </Suspense>
    );
}
