/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 25/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import { PropsWithClassNameAndChildren } from "../../../types/types";

type Props = {};

export default function Button(props: PropsWithClassNameAndChildren<Props>) {
    const className = classNames(styles.button, props.className);
    return <button className={className}>{props.children}</button>;
}
