/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 26/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import classNames from "classnames";

export default function addDomClassName(dom: HTMLElement, className: string): void {
    dom.className = classNames(dom.className, className);
}
