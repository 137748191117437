/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 25/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "src/styles/colours";

.button {
  padding: 7px 18px;
  background-color: $GREEN;
  color: white;
  font-family: "Museo Sans", "museo-sans", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  border-radius: 23px;
  border: 3px solid $GREEN_LIGHT;
  outline: none;
  cursor: pointer;

  &:active {
    //background-color: pink;
    transform: translate(0, 2px);
    transition: all .2s ease-in-out;
  }
}
