/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 25/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import styles from "./Footer.module.scss";
import React from "react";
import classNames from "classnames";
import CampableLogo from "../../../resources/widget/svg/campable_logo.svg";
import { PropsWithClassNameAndChildren } from "../../../types/types";

type Props = {};

export default function Footer(props: PropsWithClassNameAndChildren<Props>) {
    const className = classNames(styles.footer, props.className);
    return (
        <div className={className}>
            <CampableLogo />
            {props.children}
        </div>
    );
}
