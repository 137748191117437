/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 25/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export default function injectStyles(urlRef: string, id: string): void {
    const currentLink = document.getElementById(id);
    if (currentLink) {
        return;
    }

    const link = document.createElement("link");
    link.id = id;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = `${process.env.ROOT_PLATOFRM_URL}/${urlRef}`;
    document.head.appendChild(link);
}
