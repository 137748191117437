/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 25/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


.footer {
  background-color: white;
  padding: 7px 9px;
  height: 40px;
  text-align: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  width: 100%;
  max-width: 252px;

  > svg {
    height: 100%;
    max-width: 120px;
  }
}
