/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 25/09/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


$GREEN: #5FCE17;
$GREEN_LIGHT: #9DE56E;
$GREEN_DARK: #004D40;

$GREY: #666666;
